<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FED159;"
      d="M384.51,383.481C384.51,454.455,326.979,512,255.991,512c-12.849,0-25.267-1.888-36.97-5.407
	c-52.961-15.869-91.549-64.987-91.549-123.112c0-25.361,7.348-49.01,20.036-68.924c7.874-12.391,17.824-23.325,29.352-32.345
	h158.275c11.514,9.02,21.465,19.955,29.339,32.345C377.162,334.471,384.51,358.12,384.51,383.481z"
    />
    <path
      style="fill:#FFE4A9;"
      d="M384.51,383.481C384.51,454.455,326.979,512,255.991,512c-12.849,0-25.267-1.888-36.97-5.407
	l80.924-224.382h35.19c11.514,9.02,21.465,19.955,29.339,32.345C377.162,334.471,384.51,358.12,384.51,383.481z"
    />
    <g>
      <path
        style="fill:#F6C454;"
        d="M364.48,314.562H147.513c7.874-12.391,17.824-23.325,29.352-32.345h158.275
		C346.655,291.237,356.606,302.171,364.48,314.562z"
      />
      <polygon
        style="fill:#F6C454;"
        points="244.471,356.377 267.522,356.377 304.411,356.377 304.411,333.325 267.522,333.325 
		267.522,307.761 244.471,307.761 244.471,333.325 207.581,333.325 207.581,401.114 281.359,401.114 281.359,422.801 
		267.522,422.801 244.471,422.801 207.581,422.801 207.581,445.853 244.471,445.853 244.471,471.417 267.522,471.417 
		267.522,445.853 304.411,445.853 304.411,378.064 230.633,378.064 230.633,356.377 	"
      />
    </g>
    <path
      style="fill:#65B4BB;"
      d="M414.81,126.028c-1.323,0-2.636,0.038-3.944,0.1C401.853,54.999,341.123,0,267.547,0
	c-60.612,0-112.498,37.33-133.943,90.25c-5.757-1.029-11.675-1.596-17.727-1.596c-55.309,0-100.148,44.837-100.148,100.148
	c0,55.309,44.837,100.147,100.148,100.147c26.266,0,274.335,0,298.933,0c44.99,0,81.461-36.471,81.461-81.461
	C496.271,162.5,459.8,126.028,414.81,126.028z"
    />
    <path
      style="fill:#57A3A7;"
      d="M457.28,268.726c-24.599,0-272.668,0-298.933,0c-55.309,0-100.148-44.837-100.148-100.147
	c0-31.415,14.475-59.439,37.108-77.8c-45.44,9.488-79.577,49.765-79.577,98.023c0,55.309,44.837,100.147,100.148,100.147
	c26.265,0,274.333,0,298.933,0c21.081,0,40.291-8.007,54.755-21.147C465.558,268.408,461.455,268.726,457.28,268.726z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
